<template>
<h2>Pole  : {{ dataItem }}</h2>
  <tr style="border: 1px solid red; margin-left: 50px;">
    <ul>
      <div class="nextmobile">Cofnij</div>
      <div @click="doprzodu" class="nextmobile">Dalej</div>
    </ul>
  </tr>
</template>

<script>
export default {
  props: { dataItem: Object },
  data () {
  return {
    publicPath: "@/static/images/image-avatar.png"
  }
},
methods: {
    getIMGPath(img) {
      return img ? require("@/media/images/" + img) : "";
    },
    doprzodu() { 
      this.$emit("doprzodu"); 
    } 
  },

};
</script>
<style scoped>
/* .tbl {
  border: 1px solid blue;
} { doprzodu() { this.$emit("doprzodu"); } }
tr { 
  border: solid red; 
} */
.tbl {
  border: 1px solid black;
}

tr {
  border: 1px solid blue;
  text-align: center;
}

.rowfirst {
  border: 1px solid blue;
}

.table td:last-child {
  border-right: thin solid blue;
}

.title {
  color: grey;
  font-size: 18px;
}

button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

button:hover,
a:hover {
  opacity: 0.7;
}
</style>